import { render, staticRenderFns } from "./BtnIconDelete.vue?vue&type=template&id=79f11304&scoped=true&"
import script from "./BtnIconDelete.vue?vue&type=script&lang=js&"
export * from "./BtnIconDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f11304",
  null
  
)

export default component.exports